import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'

// Media
import ButtonDown from 'img/button-down.inline.svg'

const StyledBannerHome = styled.section``

const BannerWrapper = styled.div`
  position: relative;

  @media (min-width: 992px) {
    height: calc(80vh - 80px);
    min-height: 700px;
  }

  @media (max-width: 991px) {
    height: calc(80vh - 70px);
    min-height: 300px;
    max-height: 500px;
  }
`

const StyledPlaatjie = styled(Plaatjie)<{ image: any }>`
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
`

const BannerWrapperBackground = styled.div`
  @media (max-width: 992px) {
    background-color: #000000;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    opacity: 0.25;
    z-index: 1;
  }
`

const BannerWrapperContent = styled.div`
  position: relative;
  z-index: 2;
`

const BannerContent = styled.div`
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  position: relative;

  & p:not(a) {
    margin-bottom: 1.5rem;

    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.mega};
      line-height: ${({ theme }) => theme.font.size.mega};
    }

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.large};
      line-height: ${({ theme }) => theme.font.size.large};
    }
  }

  @media (min-width: 992px) {
    background-color: ${({ theme }) => theme.color.primary};

    &:before {
      background-color: ${({ theme }) => theme.color.primary};
      content: '';
      position: absolute;
      right: 100%;
      top: 0;
      bottom: 0;
      width: 2000px;
    }
  }

  @media (max-width: 992px) {
    & h1,
    & p {
      text-align: center !important;
    }
  }
`

const BannerButton = styled.button`
  background-color: ${({ theme }) => theme.color.light};
  position: absolute;
  bottom: 50px;
  left: 50%;
  width: 50px;
  height: 50px;
  margin-left: -25px;
  border-radius: 50%;
  font-size: 0;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;

  &:hover {
    background-color: ${({ theme }) => theme.color.primary};

    & svg path {
      fill: ${({ theme }) => theme.color.light};
    }
  }

  & svg {
    height: 30px;
    width: 30px;

    & path {
      fill: ${({ theme }) => theme.color.primary};
    }
  }
`

interface BannerHomeProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
}

const BannerHome: React.FC<BannerHomeProps> = ({ fields }) => {
  const ref = React.useRef<HTMLElement>(null)

  return (
    <StyledBannerHome ref={ref}>
      <BannerWrapper>
        <StyledPlaatjie alt="banner" image={fields?.image} />
        <BannerWrapperBackground />
        <BannerWrapperContent className="container py-5 h-100">
          <div className="row h-100 d-flex align-items-end pb-5">
            <BannerContent className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 py-3 py-sm-4 py-lg-5 mb-5">
              <ParseContent
                className="pe-sm-4 pe-lg-5"
                content={fields.description || ''}
              />
            </BannerContent>
          </div>
        </BannerWrapperContent>
        <BannerButton
          type="button"
          onClick={() => {
            if (!ref.current) {
              return
            }

            window.scrollBy(0, ref.current?.clientHeight - window.scrollY)
          }}
        >
          <ButtonDown />
          Naar beneden
        </BannerButton>
      </BannerWrapper>
    </StyledBannerHome>
  )
}

export default BannerHome

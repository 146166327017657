import React from 'react'
import styled from 'styled-components'

// Elements
import ParseContent from 'components/shared/ParseContent'
import Plaatjie from '@ubo/plaatjie'
import Breadcrumbs from 'components/elements/Breadcrumbs'

interface BannerDefaultProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPost_Flexcontent_Flex_Banner
  location?: any
  pageContext?: any
}

const StyledBannerDefault = styled.section``

const BannerWrapper = styled.div`
  position: relative;
  margin-bottom: 50px;

  @media (min-width: 992px) {
    height: 500px;
  }

  @media (max-width: 991px) {
    height: 400px;
  }

  @media (max-width: 767px) {
    height: 300px;
  }

  @media (max-width: 575px) {
    height: 250px;
  }
`

const BannerPlaatjie = styled.div`
  position: absolute !important;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1;
`

const StyledPlaatjie = styled(Plaatjie)`
  height: 100%;
  max-width: 2000px;
  margin: 0 auto;
  display: block;
`

const BannerContent = styled.div`
  position: relative;
  z-index: 2;
`

const Content = styled(ParseContent)`
  color: ${(props) => props.theme.color.light};
`

const BannerContentBlock = styled.div`
  background-color: ${({ theme }) => theme.color.primary};
  border-bottom-right-radius: 25px;
  border-top-right-radius: 25px;
  position: relative;
  top: 50px;

  &:before {
    content: '';
    background-color: ${({ theme }) => theme.color.primary};
    position: absolute;
    right: 100%;
    top: 0;
    bottom: 0;
    width: 2000px;
  }
`

const BannerDefault: React.FC<BannerDefaultProps> = ({
  fields,
  location = {},
  pageContext = {},
}) => (
  <StyledBannerDefault>
    <BannerWrapper>
      <BannerPlaatjie>
        <StyledPlaatjie alt="banner" image={fields?.image} />
      </BannerPlaatjie>
      <BannerContent className="container h-100">
        <div className="row h-100">
          <div className="col-sm-8 col-md-6 col-lg-5 col-xl-4 h-100 d-flex align-items-end">
            <BannerContentBlock className="py-4 py-md-5 pe-5 w-100">
              <Content content={fields.description || ''} />
              <div className="mt-3">
                <Breadcrumbs location={location} pageContext={pageContext} />
              </div>
            </BannerContentBlock>
          </div>
        </div>
      </BannerContent>
    </BannerWrapper>
  </StyledBannerDefault>
)

export default BannerDefault
